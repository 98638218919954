html,
body {

  padding: 0;
  margin: 0 auto;
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: normal;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

.flex {
  display: flex;
}

.middle {
  display: flex;
  align-items: center;
  /*justify-content: center;*/
}

.middle-p {
  font-size: 2.3vw;
}

.middle-div {
  margin-top: -4vw;
  margin-left: 12vw;
}

.content {
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  /* max-width: 1280px; */
}

#content {
  padding: 0 8vw;
}

.hr {
  /* filter: alpha(opacity=100); */
  width: 80%;
  opacity: 0.5;
}
.login {
  width: 100%;
  color: #f4f5f7;
  box-sizing: border-box;
  padding: 0 3.333vw;

}

.login .logo {
  margin-bottom: 7vw;
  margin-left: 4.199121vw;
  width: 10.588vw;
  height: 2.5536vw;
}



.left div {
  font-size: 2.3529vw;
}




.login form input {
  width: 100%;
}

.login form input[type="radio"] {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin: 5px 10px 5px 0;
}

.login form .flex input {
  width: 100%;
}

.flex>span {
  font-size: 1.6vw;
}


.login .left li {
  list-style-type: disc;
  font-size: 20px;
  line-height: 1.875;
}

.login .right {
  width: 26.3888vw;
  height: 28.4722vw;
  min-width: 300px;
  position: relative;
  /*margin-left: 9vw;*/
  margin-right: 3vw;
}

.login .card {
  /* width: 15.843vw;
  height: 15.464vw; */
  position: absolute;
  bottom: -2.43vw;
  left: -10.1vw;
}

.login form {
  width: 420px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
  color: black;
  padding: 20px 26px;
  box-sizing: border-box;
  z-index: 66;
  position: relative;
  z-index: 9;
}

.register-form {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 236px;
    height: 214px;
    // background: url("./img/card.png") no-repeat center center;
    position: absolute;
    right: 84%;
    bottom: -40px;
    
  }
}

.tab-menu {
  /*width: 9.1666vw;*/
  width: 40.667vw;
  height: 10.4vw;
  background: RGBA(225, 231, 248, 0.5);
  
  border-radius: 0.667vw;
  text-align: center;
  color: #102351;
  line-height: 1.5;
  font-size: 3.733vw;
  color: #102648;
  font-weight: 400;
  margin-bottom: 10px;
  padding: 10px 0;
  cursor: pointer;
}

// .tab-menu:not(:last-child) {
//   margin-right: 20px;
// }

.tab-menu.active {
  background: RGBA(196, 211, 255, 0.5);
  color: #3879E4;
}

.login form input {
  width: 100%;
  height: 44px;
  font-size: 14px;
  line-height: 1;
  // border-radius: 4px;
  border: 1px solid #dbdfe7;
  color: #333;
  padding: 0;
  padding-left: 15px;
  box-sizing: border-box;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;

  border-top: none;
  border-left: none;
  border-right: none;
}

.form-group {
  position: relative;
  margin: 15px auto;
}

// .form-group-button {
//   margin-top: 3vw;
// }

.login form .send {
  min-width: 80px;
  flex-shrink: 0;
  white-space: nowrap;
  height: 44px;
  border: 1px solid #102648;
  color: #3879E4;
  background: white;
  border: none;
  font-size: 14px;
  box-sizing: border-box;

  &.disabled {
    background: white;
    pointer-events: none;
  }
}

.login form .send[disabled],
.login form .submit[disabled] {
  background: #d8d8d8;
  cursor: not-allowed;
}

.login form .send-box {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.login form .send-box input {
  padding-right: 15px;
  box-sizing: border-box;
  background: inherit;
  border-radius: 4px 0 0 4px;
  border: none;
}

.login form .submit {
  width: 100%;
  display: block;
  min-height: 100%;
  height: 50px;

  border-radius: 8px;
  background: #F8A516;
  border: none;
  color: #142457;
  font-size: 16px;
}

.login form p {
  margin: 0 auto;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 1.5;
}

.login form p a {
  color: #003fe6;
  font-size: 12px;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.swiper {
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0 auto;
  &.swiper-guide {
    width: 588px;
  }
}


.swiper-pagination {
  position: static !important;
  margin-top: 40px;

  .swiper-pagination-bullet {
    background: #031B4E;
    opacity: 1;
    border: 2px solid transparent;
    border-radius: 50%;
    box-sizing: content-box;
    transition: 0.25s;

    &.swiper-pagination-bullet-active {
      background: #fff;
      border-color: #031B4E;
      transform: scale(1.2);
      transition: 0.25s;
    }
  }
}

.error {
  font-size: 13px;
  color: #f00;
  padding-top: 5px;
}

.dropdown {
  z-index: 99;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  cursor: pointer;

  >span {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    &:after {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid #000;
      border-top: none;
      border-left: none;
      transform: rotate(45deg);
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    max-height: 300px;
    overflow-y: scroll;
    background: #fff;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);

    &.active {
      display: block;
      padding-top: 10px;
    }

    >div {
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      color: #000;
      transition: 0.25s;

      &:hover {
        background: rgba(100, 100, 100, 0.1);
        transition: 0.25s;
      }
    }
  }
}

// .register-mobile {
//   border: 1px solid #dbdfe7;

//   input {
//     border: none !important;
//     outline: none;
//   }
// }

.register-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .banner-title {
    width: 650px;
  }

  h2 {
    font-size: 60px;
    margin: 0 0 40px;
  }

  ul {
    margin: 0;
    padding-left: 10px;
    font-size: 20px;

    li {
      margin-top: 10px;
    }
  }
}

.slide-container {
  position: relative;
}

.swiper-navigation {
  display: none;

  .swiper-navigation-prev,
  .swiper-navigation-next {
    position: absolute;
    top: 50%;
    transform: translateY(-53%);
    cursor: pointer;
    z-index: 9;

    &.disabled {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  .swiper-navigation-prev {
    left: -70px;
  }

  .swiper-navigation-next {
    right: -70px;
  }
}

@media(min-width:1480px) {
  .swiper-navigation {
    display: block;
  }
}



@media(max-width:1080px) {
  .container {
    width: 100%;
    box-sizing: border-box;
    padding-right: 30px;
    padding-left: 30px;
  }

  .register-banner {
    flex-direction: column;

    .banner-title {
      width: 100%;
      margin-bottom: 40px;

      h2 {
        font-size: 36px;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding-left: 0;
        font-size: 14px;
      }
      
    }

      .register-form {
        &::before{
          display:none;
        }
      }
  }

  .login form {
    width: 100%;
  }

  // .tab-menu {
  //   margin-right: 20px;
  // }

  .swiper-pagination {
    margin-top: 20px;
  }



}

#dropdown-none{
  position: absolute;
  right: -19px;
  top:-12px;
}

